import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { navbarMargin, footerHeight } from '../../constants';


const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  height: calc(100vh - (${navbarMargin} + ${footerHeight}));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
`;

const LineSeparator = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  height: 17px;
  width: 90%;
  max-width: 476px;
`;

const Caption = styled.h1`
  margin-top: 13px;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;

const Container404 = styled.div`
  position: relative;
`;

const Haze = styled.img`
  position: absolute;
  top: -130px;
  left: 28%;
  width: 140px;
  height: 100%;
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.extraSmall} ){
    top: -179px;
    width: 179px;
    height: 100%;
  }
`;

const NotFoundLayout = () => {
  const { t } = useTranslation(['common']);
  const { haze, error404 } = useStaticQuery(graphql` 
      query {
          error404: file(name: { eq: "404" }) {
              publicURL
          }
          haze: file(name: { eq: "haze404" }) {
              publicURL
          }
      }
  `);

  return (
    <Container>
      <LineSeparator />
      <Container404>
        <Haze src={haze.publicURL} />
        <img src={error404.publicURL} alt="404" />
      </Container404>
      <LineSeparator />
      <Caption className="fontCaviarDreams">
        {t('common:404.title')}
      </Caption>
    </Container>
  );
};

export default NotFoundLayout;
